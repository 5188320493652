var s = sessionStorage.getItem('accessiblity-size');
// console.log(s);
if( s ) {
  fontSet(s);
}
window.addEventListener('load', function() {
  document
    .querySelector('.font-size-increase')
    .addEventListener('click', function() {
      fontAdjust(1)
    }, false);

  document
    .querySelector('.font-size-decrease')
    .addEventListener('click', function() {
      fontAdjust(-1)
    }, false);
}, false);

function fontAdjust(direction) {
  var s = parseInt(document.querySelector('html').style.fontSize);
  var s = isNaN(s) && !isFinite(s) ? 12 : s;
  var ns = s+(direction*1);
  fontSet(ns);
}

function fontSet(value) {
  console.info("Adjust font", value);
  if( value >= 11 && value <= 14 ) {
    document.querySelector('html').style.fontSize = value + "pt";
    sessionStorage.setItem('accessiblity-size',value);
  }
}
