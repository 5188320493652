$(function() {
	if ($("body").hasClass("smooth-scroll")) {
		$('a[href*="#"]:not([href="#"])').click(function() {
			if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
				var target = $(this.hash);
				target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
				console.log(this.hash,target,target.offset());
				if (target.length) {
					$('html, body').animate({
						scrollTop: $(window).scrollTop()+target.offset().top
					}, 1000);
					return false;
				}
			}
		});
	}
});
